import { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
import Head from 'next/head'
import { useRouter } from 'next/router'
import useWeb3Provider from 'hooks/useActiveWeb3React'
import { useOptionsInvitedAddress, useOptionsCurrentBlockNumber } from 'state/options/hooks'
import { defaultReferrerAddress } from 'state/options/types'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import Container from './Container'

const StyledPage = styled(Container)`
  min-height: calc(100vh - 64px);
  padding-top: 16px;
  padding-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`

export const PageMeta: React.FC<React.PropsWithChildren<{ symbol?: string }>> = ({ symbol }) => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const { provider } = useWeb3Provider()
  const { pathname, query } = useRouter()
  const [optionsInvitedAddress, setOptionsInvitedAddress] = useOptionsInvitedAddress()
  const [optionsCurrentBlockNumber, setOptionsCurrentBlockNumber] = useOptionsCurrentBlockNumber()
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })
  const cakePriceUsdDisplay = cakePriceUsd ? `$${cakePriceUsd.toFixed(3)}` : '...'
  useEffect(() => {
    const inviteCode: any = query?.inviteCode
    const code = localStorage.getItem('inviteCode')
    if (!inviteCode) {
      if (code === 'undefined' || code === 'null' || code === '' || !code) {
        localStorage.setItem('inviteCode', defaultReferrerAddress)
        setOptionsInvitedAddress(defaultReferrerAddress)
      } else {
        setOptionsInvitedAddress(code)
      }
    } else {
      localStorage.setItem('inviteCode', inviteCode)
      setOptionsInvitedAddress(inviteCode)
    }
  }, [query, setOptionsInvitedAddress])
  const fetchBlockNumber = async () => {
    const number = await provider.getBlockNumber()
    setOptionsCurrentBlockNumber(number)
  }
  useEffect(() => {
    fetchBlockNumber()
  }, [provider])
  const pageMeta = getCustomMeta(pathname, t, locale) || {}
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
  // let pageTitle = cakePriceUsdDisplay ? [title, cakePriceUsdDisplay].join(' - ') : title
  let pageTitle = 'Skaiswap'
  // console.log('pageTitle', [title, cakePriceUsdDisplay].join(' - '))
  if (symbol) {
    pageTitle = [symbol, title].join(' - ')
  }

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Head>
  )
}

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  symbol?: string
}

const Page: React.FC<React.PropsWithChildren<PageProps>> = ({ children, symbol, ...props }) => {
  return (
    <>
      <PageMeta symbol={symbol} />
      <StyledPage {...props}>{children}</StyledPage>
    </>
  )
}

export default Page
