var Icon = function() {
    return {
        home: {
            src: "/images/icons/home.png",
            alt: "home"
        },
        trade: {
            src: "/images/icons/trade.png",
            alt: "trade"
        },
        liquidity: {
            src: "/images/icons/liquidity.png",
            alt: "liquidity"
        },
        pledge: {
            src: "/images/icons/pledge.png",
            alt: "pledge"
        },
        invite: {
            src: "/images/icons/invite.png",
            alt: "invite"
        },
        nft: {
            src: "/images/icons/nft.png",
            alt: "nft"
        }
    };
};
export default Icon;
