import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  height: 100%;\n  // background: ",
        ";\n  // border-top: 1px solid ",
        ';\n  padding-bottom: env(safe-area-inset-bottom);\n  html[data-useragent*="TokenPocket_iOS"] & {\n    padding-bottom: 45px;\n  }\n  z-index: 20;\n  .wallet_box,\n  a {\n    width: 100%;\n    display: block;\n    padding: 16px 21px;\n    position: relative;\n    display: flex;\n    align-items: center;\n    &.active {\n      p {\n        color: ',
        ";\n      }\n    }\n    h3 {\n      margin-right: 12px;\n      width: 20px;\n      img {\n        width: 100%;\n        display: block;\n      }\n    }\n    p {\n      font-size: 14px;\n      color: #fff;\n      position: relative;\n    }\n    span {\n      position: absolute;\n      right: 22px;\n      top: 20px;\n      img {\n        width: 7px;\n        display: block;\n      }\n    }\n\n    button {\n      position: absolute;\n      left: 0;\n      top: 0;\n      opacity: 0;\n      width: 100%;\n    }\n  }\n  .wallet_wrap {\n    width: 100%;\n    position: relative;\n    button {\n      margin-top: 0;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
var StyledBottomNav = styled.div.withConfig({
    componentId: "sc-67d3456b-0"
})(_templateObject(), function(param) {
    var theme = param.theme;
    return theme.colors.backgroundAlt;
}, function(param) {
    var theme = param.theme;
    return theme.colors.cardBorder;
}, function(param) {
    var theme = param.theme;
    return theme.colors.primary;
});
export default StyledBottomNav;
